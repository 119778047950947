#root {
    min-width: 1280px;
}

.header {
    text-align: center;
    line-height: 50px;
    height: 50px;
    font-size: 16px;
}

.row > .time, .row > .operator, .row > .amount {
    display: inline-block;
    width: 16.6%;
    text-align: center;
    line-height: 30px;
    border-bottom: 1px solid #dfdfdf;
    vertical-align: bottom;
}

.row > .title {
    font-weight: bold;
    color: #666 !important;
}

.row > .operator {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.row > .amount.add {
    color: #f44336;
}

.row > .amount.sub {
    color: #4caf50;
}

.row > .operator {
    color: #03a9f4;
}

.row > .time {
    color: #666;
}

.statistics {
    font-weight: bold;
    line-height: 30px;
    padding: 15px;
}

.statistics > .amount {
    color: #03a9f4;
    padding-left: 5px;
    padding-right: 5px;
}